import React, {useEffect, useState} from "react";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";

import "./surveyHeader.css"

import CircularProgress from '@mui/material/CircularProgress';

const noClientFound = require("../images/logos/noLogoFound.png");
const mcdLogo = require("../images/logos/McDLogo.png");

function SurveyHeader(props) {

    const [currentImage, setCurrentImage] = useState({
        imageFile: noClientFound,
        imageName: "noClientFound"
    });

    const [loadingImage, setLoadingImage] = useState(true);

    const [rawSearchParams] = useSearchParams();

    useEffect(() => {

        grabImageName();

        //optional return function
    },[]); //The dependency array this will always run atleast 1 time because of component did mount

    const grabImageName = async () => {

        console.log("grabImageName")

        const searchParamsArray = Object.entries([...rawSearchParams]);
    
        let storeUid = "";

        for(const currentParam of searchParamsArray) {

            const currentParamName = currentParam[1][0];
            const currentParamValue = currentParam[1][1];

            if(currentParamName === "storeUid") {

                storeUid = currentParamValue;

            };
        
        };

        try{

            const staticLogoNameApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/api/grabImages/staticLogoType?storeUid=${storeUid}`;

            console.log(staticLogoNameApiUrl)

            const getStaticLogoNameConfig = {
                method: "get",
                maxBodyLength: Infinity,
                url: staticLogoNameApiUrl,
            };

            let response = await axios.request(getStaticLogoNameConfig);

            const responseData = response.data;

            if(responseData !== undefined && responseData.staticImageIdentifier !== undefined) {

                const storeIdentifier = responseData.staticImageIdentifier;

                const clientImage = require(`../images/logos/${storeIdentifier}.png`)

                setCurrentImage({
                    imageFile: clientImage,
                    imageName: storeIdentifier
                });

                setLoadingImage(false);                    

            }
            
        }catch(error) {

            console.log(error)

            const noClientFoundImage = require("../images/logos/noLogoFound.png");
            setCurrentImage({
                imageFile: noClientFoundImage,
                imageName: "No Logo Found"
            });

            setLoadingImage(false);

        }

    };

    const generateSelectedImage = () => {

        if(props.showImage === false) {

            return ""

        }else if(props.defaultImage === true) {

            return (

                <img src={mcdLogo} alt="companyLogo" className="groupLogo" />
            )

        }else if(loadingImage === true) {

            return (
                <CircularProgress />
            )

        }else {

            return (
                <img src={currentImage.imageFile} alt="companyLogo" className="groupLogo" />
            )
            
        }

    };
 
    return(

        <div className="surveyHeaderContainer">

            <div className="surveyHeaderLogoContainer">

                {generateSelectedImage()}

            </div>

            <div className="surveyHeaderTextContainer">

                <p className="surveyHeader">{props.headerText}</p>
            
            </div>

        </div>

    )

};

export default SurveyHeader;