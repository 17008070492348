import React from "react";

/* ---MUI Elements--- */
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import "./renderServiceTicket.css";

function RenderServiceTickets(props) {

    const status = props.currentTicketInfo.status;
    const loadingButtonStatus = props.loadingButtonStatus;

    const progressWheelSize = "20px";
    const progressWheelStyle = {width: progressWheelSize, height: progressWheelSize, color: "black"}

    return(

        <div className="renderedServiceTicketContainer" key={`key_${props.currentTicketInfo.key}-location_${props.currentTicketInfo.storeNumber}`}>

            <div className="renderedServiceTicket_DescriptionContainer">

                <p style={{fontWeight: "bold"}}>Description:</p>
                <p style={{paddingLeft: "10px"}}>{props.currentTicketInfo.ticketDescription}</p>

            </div>

            <div className="renderedServiceTicket_ImageContainer">

                <img src={props.currentTicketInfo.pictures.imageLinksList[0]} alt="image of broken equipment" className="serviceTicketImage imageOne"/>

            </div>

            <div className="renderedServiceTicket_ExtraInfoContainer">

                <div className="renderedServiceTicket_ExtraInfoContainer_Age">

                    <p style={{fontWeight: "bold"}}>Age:</p>
                    <p style={{paddingLeft: "10px"}}>{props.currentTicketInfo.age} Days</p>

                </div>

                <div className="renderedServiceTicket_ExtraInfoContainer_Status">
                    
                    <p style={{fontWeight: "bold"}}>Status:</p>
                    <p style={{paddingLeft: "10px"}}>{props.currentTicketInfo.status}</p>

                </div>
                
                
            
            </div>

            <div className="renderServiceTicket_ButtonContainer">

                <div className="renderServiceTicketButton">

                    <Button
                        variant="contained"
                        sx={{height: "100%"}}
                        disabled={status === "In Progress" ? true:false} //disables it depending on in progress is true or false
                        onClick={() => props.serviceTicketStatusChange({type: "start", key: props.currentTicketInfo.key})}
                    >
                        {loadingButtonStatus.start === false ? "Start" : <CircularProgress style={progressWheelStyle}/>}
                    </Button>

                </div>

                <div className="renderServiceTicketButton">

                    <Button
                        variant="contained"
                        sx={{height: "100%"}}
                        disabled={status !== "In Progress" ? true:false} //disables it depending on in progress is true or false
                        onClick={() => props.serviceTicketStatusChange({type: "complete", key: props.currentTicketInfo.key})}             
                    >
                        {loadingButtonStatus.complete === false ? "Complete" : <CircularProgress style={progressWheelStyle}/>}
                    </Button>

                </div>

            </div>

        </div>
    )

};

export default RenderServiceTickets;