import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import "./submitScreen.css";

function SubmitScreen(props) {

    const navigate = useNavigate();

    const [submitScreenState, setSubmitScreenState] = useState("");

    const submitScreenFinish = (action) => {

        if(action === "back"){

            /* reloads page */
            navigate(0)

        }else if(action === "finish") {

            navigate("/formSubmission"); //sends us to the home page
    
            let submitScreenContainerInfo = document.getElementById("submitScreenContainer");
    
            submitScreenContainerInfo.style.display = "none";

        }


    }

    const displayGenerator = () => {

        const waitingForResponse = props.displayInfo.waitingForResponse;
        const displayGeneratorMessage = props.displayInfo.message;

        if(waitingForResponse === true) {

            return(
                <div className="displayGeneratorContainer">

                    <CircularProgress />

                </div>

            )

        }

    }

    return(

        <div className="submitScreenContainer" id="submitScreenContainer">

            <div className="submitUiContainer">


                <p className="submitUiSurveyName">{props.surveyName}</p>
                
                {displayGenerator()}

                <div className="submitUiMessageContainer">

                    <p className="submitUiMessage">{props.displayInfo.message}</p>
 
                </div>

                <div className="submitUiButtonContainer">

                    <Button 
                        variant="contained"
                        onClick={() => submitScreenFinish(props.displayInfo.buttonInfo.action)}
                    >
                        {props.displayInfo.buttonInfo.text}

                    </Button>

                </div>

            </div>

        </div>

    )

};

export default SubmitScreen;