import React from "react";

/* icons */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import "./submissionPage.css";

function SubmissionPage() {

    const thumbsUpIconStyle = {
        "color": "green",
        "fontSize": "300px",
        "@media(max-width: 600px)": { //if width is 600px or smaller
            "fontSize": "200px"
        }
    }

    return(

        <div className="submissionPageContainer">

            <div className="submissionPage_iconContainer">

                <CheckCircleOutlineIcon
                    sx={thumbsUpIconStyle}
                />

            </div>

            <div className="submissionPage_headerContainer">
                
                <h1>Your Feedback is appreciated</h1>
 
            </div>

            <div className="submissionPage_paragraphContainer">

                <p>Thank you for your time. Enjoy your day.</p>

            </div>

        </div>
        
    )
};

export default SubmissionPage;