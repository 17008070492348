//simple function to easily generate random strings mainly to be used for keys in .map functions
const generateMapKey = (length) => {

    //available characters
    const availableCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";

    //current key
    let mapKey = "";

    //goes through "length" times grabbing a random character from the list of characters
    for(let keyIndex = 0; keyIndex < length; keyIndex ++) {

        const characterIndex = Math.floor(Math.random()*(availableCharacters.length - 1));

        mapKey += availableCharacters[characterIndex];

    };

    return mapKey;

};

export default generateMapKey;