import React from "react";

import "./noPageFound.css"

function NoPageFound() {

    return(
        
        <div className="noPageFoundContainer">
            
            <div className="pageNotFoundHeaderContainer">

                <h1 className="noPageFoundText pageNotFoundHeader">404</h1>

            </div>

            <div className="pageNotFoundTextContainer">

                <p className="noPageFoundText pageNotFoundParagraph">The page you are accessing does not exist.</p>

            </div>

            {/* <div className="pageNotFoundImageContainer">

                <img src={image} alt="404 Image Icon" className="imagePageNotFound" />

            </div> */}
            

        </div>
    )

};

export default NoPageFound;