import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import SubmitScreen from "../../submitScreen/submitScreen";
import SurveyHeader from "../../surveyHeader/surveyHeader";
import ProgressBar from "../../progressBar/progressBar";
import FormElementCreator from "../../formElementCreator/formElementCreator";

import "./satisfactionSurvey.css";

function SatisfactionSurvey(props) {

    /* keeps track of the current question */
    const [questionState, setQuestionState] = useState(0);

    const [questionBaseColor, setQuestionBaseColor] = useState("");

    const [hasFormBeenSubmit, setHasFormBeenSubmit] = useState(false);

    const [submitScreenProps, setSubmitScreenProps] = useState({
        waitingForResponse: true,
        message: "Submitting Response",
        buttonInfo: {
            text: "Finish",
            action: "finish"
        }
    });

    const [currentStoreLogo, setCurrentStoreLogo] = useState({
        name:"noLogoFound",
        attemptMade: false
    });

    const [rawSearchParams] = useSearchParams();

    /* grabs the base color of the question div so use to reset it when its color changes as a warning */
    useEffect(() => {

        console.log("satisfactionSurvey")

        const currentDivInformation = document.getElementById("testingFormLabelName");

        /* grabs the current background color of the div and if there is no then its default is white */
        let currentBackgroundColor = (currentDivInformation.style.backgroundColor === "") ? "#FFFFFF" : currentDivInformation.style.backgroundColor;

        setQuestionBaseColor(currentBackgroundColor);

    },[]);

    /* checks if the index of the current question is the same as the index of the final question and changes the next button to submit instead */
    const updateButtonText = (buttonType) => {
        
        let buttonText = "";

        if (buttonType === "back") {

            buttonText = "Back";

            let backButtonTextInfo = document.getElementsByClassName("backButtonText");

            /* just double checks that we the elements are not empty preventing things from breaking if they somehow are */
            if (backButtonTextInfo.length === 0) {
                
                //console.log("no css info found likely because component didnt finish mounting")
                return "Back"

            }

            if (questionState === 0) {
                
                backButtonTextInfo[0].style.opacity = ".25";

            }else {

                backButtonTextInfo[0].style.opacity = "1";

            }

        }

        if(buttonType === "next") {

            const finalQuestionIndex = questionInfoState.length -1;
    
            buttonText = "Next";
    
            if (questionState === finalQuestionIndex) {
               
                buttonText = "Submit"
    
            }

        }

        

        return buttonText

    };

    
    
    /* list of question info that may be moved somewhere else eventually */
    const questionList = [
        {
            question:"How was your overall experience today",
            selectionType:"radial",
            options:[
                "Excellent", 
                "Good", 
                "Fair", 
                "Poor"
            ],
            choice: "",
            databaseTag: "Satisfaction Q1"
        },
        {
            question: "How was the quality of your food today?",
            selectionType: "radial",
            options: [
                "Excellent", 
                "Good", 
                "Fair", 
                "Poor"
            ],
            choice: "",
            databaseTag: "Satisfaction Q2"
        },
        {
            question: "How long did you wait for your order?",
            selectionType: "radial",
            options: [
                "Less than 5 minutes", 
                "5-10 minutes",
                "More than 10 minutes"
            ],
            choice: "",
            databaseTag: "Satisfaction Q3"
        },
        {
            question: "How friendly was the staff?",
            selectionType: "radial",
            options: [
                "Very Friendly", 
                "Friendly", 
                "Neutral", 
                "Unfriendly"
            ],
            choice: "",
            databaseTag: "Satisfaction Q4"
        },
        {
            question: "Was the restaurant clean?",
            selectionType: "radial",
            options: [
                "Very Clean", 
                "Clean", 
                "Not Very Clean", 
                "Dirty"
            ],
            choice: "",
            databaseTag: "Satisfaction Q5"
        },
        {
            question: "Did you experience or see any broken equipment?",
            selectionType: "radial",
            options: [
                "Yes",
                "No"
            ],
            choice: "",
            databaseTag: "Satisfaction Q6"
        },
        {
            question: "Would you visit us again?",
            selectionType: "radial",
            options: [
                "Yes",
                "No"
            ],
            choice: "",
            databaseTag: "Satisfaction Q7"
        },
    ];
    
    /* state of the question info to keep track of what the user has chosen */
    const [questionInfoState, setQuestionInfo] = useState(questionList);

    /* takes the info that is updated in the child component and the question number and updated the current state to have the response */
    const updateQuestionInfo = (choice, questionNumber) => {

        /* makes sure not to break if these are not able to be sent from the child */
        if(choice !== undefined && questionNumber !== undefined) {

            /* makes a deep copy of the state */
            let deepCopyQuestionList = [...questionInfoState];
    
            let currentQuestion = deepCopyQuestionList[questionNumber];

            currentQuestion.choice = choice;
    
            /* updates the copy of the state with the new info */
            deepCopyQuestionList[questionNumber] = currentQuestion;
    
            /* updates the state with the newly selected choice for the form element in question */
            setQuestionInfo(deepCopyQuestionList);

        }

    };

    /* grabs the url info such as path, search params and more */
    const location = useLocation();

    /* grabs the url parameters from the request which is used to determine which project it goes into */
    const logParams = () => {

        /* grabs the query info */
        const queryParams = new URLSearchParams(location.search);

        let paramsArray = [];

        /* maps all of the query info key and value pairs */
        queryParams.forEach((value, key) => {

            paramsArray.push({ key, value });

        });

        //console.log(paramsArray)

    };

    /* determines if a question has been answers in regards to if the user can proceed to the next question */
    const checkIfFieldIsFilled = () => {

        let currentFieldValue = questionInfoState[questionState].choice;

        let isFieldFilled = false;

        if (currentFieldValue !== "") isFieldFilled = true;

        return isFieldFilled;

    };

    const missingQuestionAnswerNotification = () => {

        const currentDivInformation = document.getElementById("testingFormLabelName");

        /* grabs the current background color of the div and if there is no then its default is white */
        let currentBackgroundColor = questionBaseColor;

        currentDivInformation.style.transitionDuration = ".1s";
        currentDivInformation.style.backgroundColor = "rgb(255 92 92)";

        setTimeout(() => {

            currentDivInformation.style.transitionDuration = "1s";
            currentDivInformation.style.backgroundColor = currentBackgroundColor;

        }, 500)

    };

    /* if the button click is allowed, the button them selves will then be able to blink the correct color */
    const buttonFeedbackResponse = (direction) => {

        //console.log("buttonFeedback was pressed")

        let selectedButtonContainerInfo = "";
        let blinkerColor = "";

        if(direction === -1) {

            selectedButtonContainerInfo = document.getElementsByClassName("backButtonContainer");

            blinkerColor = "#f96464";

        }else {

            selectedButtonContainerInfo = document.getElementsByClassName("nextButtonContainer");
            blinkerColor = "#3bcb3b";

        }
        
        if (selectedButtonContainerInfo.length !== 0) {

            const selectedButtonContainerBackgroundColor = selectedButtonContainerInfo[0].style.backgroundColor;

            selectedButtonContainerInfo[0].style.transitionDuration = ".05s";
            selectedButtonContainerInfo[0].style.backgroundColor = blinkerColor;

            setTimeout(() => {

                selectedButtonContainerInfo[0].style.transitionDuration = ".5s";
                selectedButtonContainerInfo[0].style.backgroundColor = selectedButtonContainerBackgroundColor;

            }, 100)

        }

    };

    /* allows for the moving forward and back of questions */
    const questionNavigation = (direction) => {

        let isFieldFilled = checkIfFieldIsFilled();

        if(direction === 1 && questionState < questionInfoState.length - 1 && isFieldFilled === false) {

            missingQuestionAnswerNotification();

        }else if(direction === 1 && questionState === questionInfoState.length - 1 && isFieldFilled === true) {
            
            submitForm();

        }else if (direction === 1 && questionState < questionInfoState.length - 1) {

            setQuestionState(questionState + direction);
            buttonFeedbackResponse(direction)

        }else if (direction === -1 && questionState > 0) {

            setQuestionState(questionState + direction);
            buttonFeedbackResponse(direction)

        }

        /* */

        updateButtonText();

    };

    /* simple function to display submit screen */
    const displaySubmitScreen = () => {

        let submitScreenContainerInfo = document.getElementById("submitScreenContainer");
        submitScreenContainerInfo.style.display = "flex";

    }

    const sleep = (seconds) => {
        return new Promise((resolve) => setTimeout(resolve, seconds*1000))
    }

    const submitForm = async () => {

        //console.log("submitForm is called")

        if(hasFormBeenSubmit === false) {

            //console.log("submitForm will try to import")

            setHasFormBeenSubmit(true);

            const paramsList = Object.entries([...rawSearchParams]);
            //returns an array of arrays. If the params were snack=cookie&drink=milk it would look like this
            /* 
                [
                    [
                        "0",
                        ["snack", "cookie"]
                    ],
                    [
                        "1",
                        ["drink", "milk"]
                    ]
                ]
            */
    
            let organizedParamList = {};
    
            /* goes through the param info creating an key and relevant info for each query param */
            paramsList.forEach((currentParamInfo) => {
    
                const paramKey = currentParamInfo[1][0];
                const paramValue = currentParamInfo[1][1];
                const paramIndex = currentParamInfo[0];
    
                organizedParamList[paramKey] = { "index": paramIndex, "value": paramValue };
    
            })
    
            //console.log(organizedParamList);
    
            if (organizedParamList["storeUid"] === undefined) {
    
                //console.log("Please Reload")
    
            };
    
            const apiPostBody = {};
    
            /* goes through each array of the index ot grab the question info and the database tag */
            questionInfoState.forEach((questionInfoObject) => {
    
                const databaseQuestionTag = questionInfoObject.databaseTag;
                const questionAnswerChoice = questionInfoObject.choice;
    
                apiPostBody[databaseQuestionTag] = questionAnswerChoice;
    
            });
    
            const storeUidParameter = organizedParamList.storeUid;
    
            if (storeUidParameter !== undefined) {

                let submitScreenPropsCopy = {...submitScreenProps};

                submitScreenPropsCopy.waitingForResponse = true;
                submitScreenPropsCopy.message = "Submitting Response to server."
                submitScreenPropsCopy.buttonInfo = {
                    text: "Waiting for response",
                    action: ""
                }

                setSubmitScreenProps(submitScreenPropsCopy)
                displaySubmitScreen();
    
                const storeUidValue = storeUidParameter.value;
    
                const surveyAppUrl = `${process.env.REACT_APP_API_ENDPOINT}/api/satisfactionSurvey/satisfaction?storeUid=${storeUidValue}`;
        
                let axiosGetTableHeaderInfoConfig = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: surveyAppUrl,
                    data: apiPostBody
                };
        
                try {

                    let response = await axios.request(axiosGetTableHeaderInfoConfig);

                    await sleep(2);

                    let successfulFormSubmissionPropsCopy = {...submitScreenProps};

                    successfulFormSubmissionPropsCopy.waitingForResponse = false;
                    successfulFormSubmissionPropsCopy.message = "Response Submitted"
                    successfulFormSubmissionPropsCopy.buttonInfo = {
                        text: "Finish",
                        action: "finish"
                    };

                    setSubmitScreenProps(successfulFormSubmissionPropsCopy)
        
                } catch (error) {
                    
                    if(error.response === undefined){ //usually means an issue with the call itself not the data in the call
                        
                        //console.log(error)
                        submitScreenPropsCopy.waitingForResponse = false;
                        submitScreenPropsCopy.message = "An unknown error occurred. Please try again at another time."
                        submitScreenPropsCopy.buttonInfo = {
                            text: "Back",
                            action: "back"
                        }
    
                        setSubmitScreenProps(submitScreenPropsCopy);

                    }else {

                        const importMessage = error.response.data.message;
    
                        let submitScreenPropsCopy = {...submitScreenProps};
    
                        submitScreenPropsCopy.waitingForResponse = false;
                        submitScreenPropsCopy.message = "An unknown error occurred. Please try again at another time."
                        submitScreenPropsCopy.buttonInfo = {
                            text: "Back",
                            action: "back"
                        }
    
                        setSubmitScreenProps(submitScreenPropsCopy);

                    }
        

                    //console.log(importMessage)
        
                }
    
            }else{

                let submitScreenPropsCopy = {...submitScreenProps};

                submitScreenPropsCopy.waitingForResponse = false;
                submitScreenPropsCopy.message = "Invalid url, please scan QR code and try again."
                submitScreenPropsCopy.buttonInfo = {
                    text: "Back",
                    action: "back"
                }

                setSubmitScreenProps(submitScreenPropsCopy)
                displaySubmitScreen();

            }

        }
    }

    return(
        
        <div className="satisfactionSurveyContainer">

            <Helmet>
                <title>Satisfaction Survey</title>
            </Helmet>

            <SubmitScreen 
                surveyName={"Satisfaction Survey"}
                surveyMessage={"Thank you for completing the survey! Have a nice day."}
                displayInfo={submitScreenProps}
            />

            <SurveyHeader
                showImage={true}
                defaultImage={false}
                headerText={"Satisfaction Survey"}
            />

            <ProgressBar 
                currentQuestionNumber={questionState}
                numberOfQuestions={questionInfoState.length}
            />

            <div className="formContainer">

                <FormElementCreator
                    question={questionInfoState[questionState].question}
                    selectionType={questionInfoState[questionState].selectionType}
                    options={questionInfoState[questionState].options}
                    choice={questionInfoState[questionState].choice}
                    questionNumber={questionState}
                    updateQuestionInfo={updateQuestionInfo}
                />

                <div className="navigationBarContainer">

                    <div className="navigationButtonContainer backButtonContainer" onClick={() => questionNavigation(-1)}>

                        <h2 className="navigationButtonHeader backButtonText">{updateButtonText("back")}</h2>
                    
                    </div>

                    <div className="navigationButtonContainer nextButtonContainer" onClick={() => questionNavigation(1)}>

                        <h2 className="navigationButtonHeader nextButtonText">{updateButtonText("next")}</h2>
                    
                    </div>

                </div>

            </div>   
                
        </div>
    )

};

export default SatisfactionSurvey;