import React, { useState } from "react";

function IndividualRequestLimit() {

    return(
        <div className="individualRequestLimitContainer">

            <div className="requestLimit_HeaderContainer">

                <h1>Too many requests</h1>
    
            </div>

            <div className="requestLimit_ParagraphContainer">

                <p>Too many requests have been made from this IP address. Please wait some time before trying again</p>

            </div>

        </div>
    )

};

export default IndividualRequestLimit;