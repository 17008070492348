import React, { useEffect, useState } from "react";

import LinearProgress from '@mui/material/LinearProgress';

import "./progressBar.css"

function ProgressBar(props) {

    /* converts the question progress from something like 1/7 to a percentage to be easily added to the progress bar */
    const createProgressPercentage = () => {

        const currentQuestionNumber = props.currentQuestionNumber;
        const maxQuestionNumber = props.numberOfQuestions;

        const progressPercentage = Math.round((currentQuestionNumber/maxQuestionNumber) * 100);

        return progressPercentage;

    };

    const generateCurrentQuestionNumber = () => {

        let questionNumber = props.currentQuestionNumber + 1;

        if (props.currentQuestionNumber + 1 > props.numberOfQuestions) {

            questionNumber = props.currentQuestionNumber;

        }

        return questionNumber;


    }

    return(
        <div className="progressBarContainer">

            {/* <div className="logoAndQuestionTrackingContainer">

                <div className="logoContainer">

                    {generateSelectedImage()}

                </div>

            </div> */}

            <div className="progressBarUiElementContainer">

                <LinearProgress 
                    variant="determinate"
                    value={createProgressPercentage()}
                    style={{
                        height: "25px",
                        width: "100%",
                    }}
                    sx={{
                        borderRadius: "10px",
                        "backgroundColor": "#d2e8ff",
                        "& .MuiLinearProgress-barColorPrimary": {
                            backgroundColor: "#68b3fd"

                        }
                    }}
                    
                />

                <div className="questionProgressNumberTrackerOverlay">

                    <p className="questionProgressNumberTrackerText">
                        Question {generateCurrentQuestionNumber()} of {props.numberOfQuestions}
                    </p>

                </div>
                
            </div>


        </div>
    )

};

export default ProgressBar;