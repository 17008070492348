import React, { useState } from "react";

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useScrollTrigger } from "@mui/material";

import "./formElementCreator.css";

/* props */
/* 
    question - Question Text
    selectionType - type of form element to use
    options - the choices available for the options (not always applicable)
*/
function FormElementCreator(props) {

    const [currentAnswer, setCurrentAnswer] = useState(props.choice);

    const inlineStyleInfo = {
        questionMargins: "15px"
    }

    const generateFormHtml = () => {

        let selectionType = props.selectionType;

        let formHtml = "";

        if (selectionType === "radial") formHtml = generateRadialInput();

        if (selectionType === "dropdown") formHtml = generateDropdownInput();

        return formHtml;

    };

    const updateQuestionChoice = (event, questionNumber) => {

        const choice = event.target.value;
        //console.log(choice, questionNumber)

        props.updateQuestionInfo(choice, questionNumber); //updates the selected choice for the current question

    }

    const generateRadialInput = () => {

        let questionId = props.question.replace(/\s/g, '-');
        questionId += "-Question"

        return(
            <FormControl id={questionId} style={{width: "100%"}}>

                <FormLabel 
                    style={{ 
                        paddingLeft: inlineStyleInfo.questionMargins,
                        paddingRight: inlineStyleInfo.questionMargins,
                        textAlign:"left",
                        color: "black",
                        fontWeight: "bold"
                    }}
                    id="testingFormLabelName"
                >
                    {props.question}
                </FormLabel>
                
                <RadioGroup
                    value={props.choice}
                    name="radio-buttons-group"
                    onChange={(event) => updateQuestionChoice(event, props.questionNumber)}
                    style={{ 
                        paddingLeft: inlineStyleInfo.questionMargins,
                        paddingRight: inlineStyleInfo.questionMargins,
                        flexDirection: "row"
                    }}
                    className="radioGroupOrientation"
                >

                    {props.options.map((value) => (
                        <FormControlLabel 
                            key={generateRandomKey(15)}
                            value={value}
                            control={
                                <Radio
                                    sx={{
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 30
                                        },
                                        "@media(min-aspect-ratio: 16/10)": {

                                            "& .MuiSvgIcon-root": {
                                                fontSize: 45
                                            }

                                        },

                                    }}
                                    /* size="medium" */
                                />
                            }
                            label={value}
                            style={{textAlign: "left"}}
                            className="radioSelectionOption"
                        />
                    ))}
                    

                </RadioGroup>

            </FormControl>
        )
    };

    const generateDropdownInput = () => {

        return(
            <FormControl>

                <FormLabel>{props.question}</FormLabel>

                {/* <InputLabel id="demo-simple-select-label">{props.question}</InputLabel> */}
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    //value={}
                    label="Age"
                    defaultValue={currentAnswer}
                    autoWidth
                    //onChange={}
                >
                    {props.options.map((value) => (
                        <MenuItem key={() => generateRandomKey(15)} value={value}>{value}</MenuItem>
                    ))}

                </Select>
            </FormControl>
        )
    }

    const generateRandomKey = (number) => {

        let possibleKeyCharacters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-";

        const min = 0;
        const max = possibleKeyCharacters.length -1;

        let uniqueKey = "";

        for(let currentCharacterIndex = 0; currentCharacterIndex < number; currentCharacterIndex ++) {

            /* math.random generates a random float between 0 and 1 we then multiple that number between */
            let randomNumberInRange = Math.floor(Math.random() * (max - min)) + min;

            uniqueKey += possibleKeyCharacters[randomNumberInRange]; // adds to the unique key based on the index of the random number in the large string of possible characters

        }

        return uniqueKey;

    }

    return(
        <div className="formOptionsContainer">
            {generateFormHtml()}
        </div>
    )

};

export default FormElementCreator;