import React from "react";

/* ---MUI Elements--- */
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

/* figure out how to prevent the size change */
function SubmitLoadingButton(props) {

    const renderButtonAndLoading = () => {

        if(props.loadingStatus === "loading") {

            return (
                <Button
                    disabled={props.buttonLock}
                    className="serviceTicketSearchButton"
                    variant="contained"
                    onClick={() => props.onClick()}
                >
                    <CircularProgress size={"20px"}/>

                </Button>
                
            )
            
        }else{

            return (
                <Button
                    disabled={props.buttonLock}
                    className="serviceTicketSearchButton"
                    variant="contained"
                    onClick={() => props.onClick()}
                >
                    {props.buttonText}
                </Button>
            )

        }

    }

    return (

        <div className="submitLoadingButton">
            
            {renderButtonAndLoading()}

        </div>

    )

};

export default SubmitLoadingButton;