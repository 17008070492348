import React from "react";
import { useState } from "react";

import generateMapKey from "../../globalTools/generateMapKey";

import "./homePage.css"

function HomePage() {

    const [homePageText, setHomePageText] = useState([
        "Welcome! You have found yourself on the homepage of PLACEHOLDER.",
        "If you find yourself here you are likely a little lost.",
        "Please scan a QR code to be taken to the corresponding page."
    ]);


    return(

        <div className="homePageContainer">

            <div className="homePageInfoContainer">

                <div className="homePage_HeaderContainer">

                    <h1 className="homePage_Header">PLACEHOLDER</h1>

                </div>

                <div className="homePage_ParagraphContainer">

                    {homePageText.map((paragraphText) => {

                        return(

                            <p className="homePage_P" key={`paragraph-${generateMapKey(10)}`}>{paragraphText}</p>

                        )

                    })}

                </div>

            </div>

        </div>

    )

};

export default HomePage;