import React, {useState, useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

/* ---MUI Elements--- */
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

/* ---Internal React Components--- */
import SubmitLoadingButton from "../../uiElements/submitLoadingButton";
import RenderServiceTickets from "./renderServiceTicket";
import sleep from "../../globalTools/sleepFunction";

/* ---Internal reusable code--- */
import SurveyHeader from "../../surveyHeader/surveyHeader";

import "./serviceTicketApp.css"

import sleepFunction from "../../globalTools/sleepFunction";

function ServiceTicketApp(props) {

    const StyledButton = styled(Button)(({theme}) => ({
        width: "300px",
        paddingLeft: "20px",
        paddingRight: "20px",
    }))

    const [serviceTicketSearchValue, setSearchTicketSearchValue] = useState("");
    const [serviceTicketApiList, setServiceTicketApiList] = useState([]);
    const [serviceTicketApiMessage, setServiceTicketApiMessage] = useState("");

    const [submitButtonLock, setSubmitButtonLock] = useState(false);
    const [apiCallStatus, setApiCallStatus] = useState("complete");

    const [loadingButtonStatus, setLoadingButtonStatus] = useState({});

    const [rawSearchParams] = useSearchParams();
    const navigate = useNavigate();

    /* just updated the next of the state */
    const changeServiceTicketSearchValue = (event) => {

        const changedText = event.target.value;

        setSearchTicketSearchValue(changedText)

    };

    /*  */
    const searchForServiceTicket = async () => {

        if(submitButtonLock !== true) {

            setSubmitButtonLock(true);
            setApiCallStatus("loading")

            const paramsList = Object.entries([...rawSearchParams]);

            const paramObject = {};

            /* iterates through the very weird response to the params and creates an object of the params (key:value) */
            for(const currentParam of paramsList) {

                const currentParamInfo = currentParam[1];

                paramObject[currentParamInfo[0]] = currentParamInfo[1]
            }

            const getServiceTicketListEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/serviceTicket/getServiceTicketList?storeUid=${paramObject.storeUid}`;
                    
            const axiosGetServiceTicketListSettings = {
                method: "post",
                maxBodyLength: Infinity,
                url: getServiceTicketListEndpoint,
                data: {ticketNumber: serviceTicketSearchValue}
            };

            try {

                let serviceTicketListResponse = await axios.request(axiosGetServiceTicketListSettings);

                const serviceTicketMessage = serviceTicketListResponse.data.message;
                const serviceTicketList = serviceTicketListResponse.data.serviceTickets;

                const loadingButtonStatus = {};

                for(const currentTicketInfo of serviceTicketList) {
        
                    loadingButtonStatus[currentTicketInfo.key] = {
                        start: false,
                        complete: false
                    };
        
                };
                
                setServiceTicketApiMessage(serviceTicketMessage);
                setLoadingButtonStatus(loadingButtonStatus);
                setServiceTicketApiList(serviceTicketList)

    
            } catch (error) {

                //console.log(error)
                console.log(error)

            };

            setSubmitButtonLock(false);
            setApiCallStatus("complete")

        }else{

            //console.log("button is currently disabled")

        }

    };

    const renderServiceTicketApiCallMessage = () => {

        if(serviceTicketApiMessage.length === 0){

            return (

                <p className="serviceTicketSearchMessage" style={{fontWeight: "bold"}}>Type in you ticket ticket number to search</p>

            );

        }else if(serviceTicketApiMessage === "No tickets found with provided info.") {

            return (

                <p className="serviceTicketSearchMessage" style={{color: "red", fontWeight: "bold"}}>No ticket found with provided Info</p>

            )

        }else {

            return(

                <p className="serviceTicketSearchMessage" style={{color: "green", fontWeight: "bold"}}>{serviceTicketApiList.length} Ticket(s) Found</p>
                
            )

        }

    };

    const serviceTicketStatusChange = async (status) => {

        const submissionType = status.type;
        const serviceTicketKey = status.key;
        const serviceTicketNumber = serviceTicketSearchValue;

        let updatedLoadingButtonStatus = {...loadingButtonStatus};

        if(submissionType === "start") {

            updatedLoadingButtonStatus[serviceTicketKey].start = true;

        }else if(submissionType === "complete") {

            updatedLoadingButtonStatus[serviceTicketKey].complete = true;

        };

        setLoadingButtonStatus(updatedLoadingButtonStatus);
        

        const paramsList = Object.entries([...rawSearchParams]);

        const paramObject = {};

        /* iterates through the very weird response to the params and creates an object of the params (key:value) */
        for(const currentParam of paramsList) {

            const currentParamInfo = currentParam[1];

            paramObject[currentParamInfo[0]] = currentParamInfo[1]
        }

        const updateServiceTicketEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/serviceTicket/updatedSelectServiceTicket?storeUid=${paramObject.storeUid}`;
        
        const updateServiceTicketBody = {
            ticketNumber: serviceTicketNumber,
            key: serviceTicketKey,
            updateMethod: submissionType
        };

        const axiosUpdateServiceTicketSettings = {
            method: "post",
            maxBodyLength: Infinity,
            url: updateServiceTicketEndpoint,
            data: updateServiceTicketBody
        };

        try {

            let updateServiceTicketResponse = await axios.request(axiosUpdateServiceTicketSettings);

            await sleep(2);

            searchForServiceTicket()
            //navigate("/formSubmission"); //sends us to the for submission page

        }catch (error) {

            //console.log(error)
            searchForServiceTicket()

        }

        updatedLoadingButtonStatus = {...loadingButtonStatus};

        if(submissionType === "start") {

            updatedLoadingButtonStatus[serviceTicketKey].start = false;

        }else if(submissionType === "complete") {

            updatedLoadingButtonStatus[serviceTicketKey].complete = false;

        };

        setLoadingButtonStatus(updatedLoadingButtonStatus);

    };

    return(
        <div className="serviceTicketClockInContainer">

            <Helmet>
                <title>Service Ticket Clock In</title>
            </Helmet>

            <SurveyHeader 
                showImage={true}
                defaultImage={false}
                headerText={"Service Ticket Clock In"}
            />

            <div className="serviceTicketSearchInfoContainer">

                <TextField
                    className="serviceTicketSearchBar"
                    label="Service Ticket Number"
                    value={serviceTicketSearchValue}
                    onChange={(event) => changeServiceTicketSearchValue(event)}
                />

                <SubmitLoadingButton
                    onClick={searchForServiceTicket}
                    loadingStatus={apiCallStatus}
                    buttonLock={submitButtonLock}
                    buttonText="Submit"
                />

            </div>

            <div className="serviceTicketInfoContainer">

                {renderServiceTicketApiCallMessage()}

                {serviceTicketApiList.map((currentTicket) => {

                    return (

                        <div className="individualServiceTicketContainer">
                            <RenderServiceTickets
                                currentTicketInfo={currentTicket}
                                serviceTicketStatusChange={serviceTicketStatusChange}
                                loadingButtonStatus={loadingButtonStatus[currentTicket.key]}
                            /> 
                        </div>
                        
                    )
                })}

            </div>

        </div>
    )
};

export default ServiceTicketApp;