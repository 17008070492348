import React, { useState } from "react";

import "./tooManyRequests.css";

function GlobalRequestLimit() {

    return(
        <div className="globalRequestLimitContainer">

            <div className="requestLimit_HeaderContainer">

                <h1>Server Overloaded</h1>
    
            </div>

            <div className="requestLimit_ParagraphContainer">

                <p>The server has received an unusual amount of traffic and will be down for a short time.</p>

            </div>

        </div>
    )

};

export default GlobalRequestLimit;