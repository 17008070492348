import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

/* Core Route Components */
import HomePage from "./coreRoutes/homePage/homePage";
import SatisfactionSurvey from "./coreRoutes/satisfactionSurvey/satisfactionSurvey";
import ServiceTicketApp from "./coreRoutes/serviceTicketApp/serviceTicketApp";
import SubmissionPage from "./coreRoutes/submissionPage/submissionPage";
import NoPageFound from "./coreRoutes/noPageFound/noPageFound";

/* sub routes to show if limits are reached but these are not really used */
import GlobalRequestLimit from "./tooManyRequests/globalRequestLimit";
import IndividualRequestLimit from "./tooManyRequests/individualRequestLimit";

import './App.css';

function App() {

    /* grabs the env for permissions to see if the current instance is allowed access to a route */
    const [isAuthenticated] = useState(process.env.REACT_APP_PERMISSIONS || "none");

    /* object that is used to track what routes are currently usable */
    const { routeInformationObject } = require("./routeInformationObject/routeInformationObject");

    /* grabs the inner height which is 100vh - any browser ui elements such as the address bar on the phone, and sets the root to that size */
    const documentHeight = () => {

        const innerHeightPixelSize = `${window.innerHeight}px`;

        document.getElementById("root").style.height = innerHeightPixelSize;

    };

    /* function that only runs the function passed to it after 300ms have passed since its last call */
    function debounce(func, timeout = 10) {
        //this section of code only runs on the first call of debounce

        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout)
        }

    }

    //calls debounce on initial load that then keeps a rolling timer to compare to when it was last called
    const debounceProcess = debounce(() => documentHeight());
 
    /* updates size when the window resizes */
    /* window.addEventListener("resize", documentHeight); */
    window.addEventListener("resize", debounceProcess);
    
    /* updates size on page load */
    useEffect(() => {

        documentHeight();

    }, [])

    //returns different routes based on the authentication setting from env
    const routeAuthentication = (allowedAuthenticationSetting, component) => {

        //if the auth matches, then return the provided component, otherwise navigate to /noPageFound
        //if(isAuthenticated === "all" || isAuthenticated === allowedAuthenticationSetting || isAuthenticated === "none") {

            return component
            
        //}else {
            
            return <Navigate replace to={"/noPageFound"} />
            
        //}

    };

    return (

        <Router>

            <div className="appContainer">
                
                <Routes>

                    <Route path={routeInformationObject.home} Component={HomePage} />

                    {/* conditional allowing of routes depending on process.env.REACT_APP_PERMISSIONS */}
                    <Route 
                        exact
                        path={routeInformationObject.satisfaction}
                        element={routeAuthentication("external", <SatisfactionSurvey />)}
                    />

                    {/* conditional allowing of routes depending on process.env.REACT_APP_PERMISSIONS */}
                    <Route
                        exact
                        path={routeInformationObject.serviceTicketClockIn}
                        element={routeAuthentication("internal", <ServiceTicketApp />)}
                    />

                    <Route path={routeInformationObject.formSubmission} Component={SubmissionPage} />

                    {/* endpoints for when too many calls to the server have been made. */}
                    <Route path={routeInformationObject.globalRateLimit} Component={GlobalRequestLimit} />
                    <Route path={routeInformationObject.individualRateLimit} Component={IndividualRequestLimit} />

                    {/* for when a path is added but not found */}
                    <Route path="/noPageFound" Component={NoPageFound} />
                    <Route path="*" Component={NoPageFound} />

                </Routes>

            </div>

        </Router>

    );
}

export default App;
